import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useAuth } from '../contexts/auth'
import { Button } from 'react-native-paper';
import { primaryColor } from '../Constant';

export default function Beranda() {
    const { signOut } = useAuth();
  return (
    <View style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }}>
      <Text>Beranda</Text>

      <Button 
        buttonColor={primaryColor}
        mode='contained'
        // icon={'door-open'}
        onPress={ () => signOut()}
      >
        Logout
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({})