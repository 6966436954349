import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import { Platform } from 'react-native';
import * as Location from "expo-location";
import axios from 'axios'
import LoadingScreen from './LoadingScreen';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { primaryColor } from '../Constant';
import { Button } from 'react-native-paper';

export default function Absen({route,navigation}) {
    const { photo } = route.params;
    
    const [loading_screen, set_loading_screen] = useState(false);
    const [region, setRegion] = useState(null);
    const [hasPermisionLocation, setHasPermisionLocation] = useState('');

    const [locationDisplayName, setLocationDisplayName] = useState('');
    const [checkedMocked, setCheckedMocked] = useState(null);
    
    const [pickedLocation, setPickedLocation] = useState({
        lat: 0.0,
        lng: 0.0
    });

    const mapRegion = {
        latitude: pickedLocation.lat,
        longitude: pickedLocation.lng,
        latitudeDelta: 0.001,
        longitudeDelta: 0.001,
        // latitudeDelta: 0.0922,
        // longitudeDelta: 0.0421
    };

    useEffect( () => {
        getMyLocation();
    },[])


    const getMyLocation = async () => {
        set_loading_screen(true);
        let { status } = await Location.requestForegroundPermissionsAsync();

      setHasPermisionLocation( status );
  
      if (status !== 'granted') {
          setErrorMsg('Permission to access location was denied');
          alert('Permission to access location was denied')
          return false;
      }
  
      let location = await Location.getCurrentPositionAsync({});
      console.log('location', location)
      setPickedLocation({
          lat: location.coords.latitude,
          lng: location.coords.longitude
      });

      setCheckedMocked(location.mocked);

      getOpenMap(location.coords.latitude, location.coords.longitude);
      set_loading_screen(false);
    }

    const getOpenMap = (lati,longi, type_check) => {
        console.log('check map')
      
        var config = {
          method: 'get',
          url: `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lati}&lon=${longi}`,
          headers: { }
        };
      
        axios(config)
        .then(function (response) {
            setLocationDisplayName(response.data.display_name)
            console.log("check mocked 2")
            console.log(checkedMocked);
            console.log('response.data.display_name',response.data)
            console.log("search location")
            if (checkedMocked) {
              alert("gps f detected #2");
              return false;
            }
            
        })
        .catch(function (error) {
            alert("Check Security Location Data")
            set_loading_screen(false)
            console.log(error);
            var mocked = text.mocked
        });
      }



    const handleCheckIn = () => {
        alert('masih coba')
    }   

    const handleCheckOut = () => {
        alert('masih coba')
    }

    if ( loading_screen ) {
        return (
            <LoadingScreen />
        )
    }

  return (
    <View style={{ flex: 1,}}>
        <View
            style={{
                height: '50%'
            }}
        >   
            <MapView 
                style={stylesMap.map} 

                initialRegion={mapRegion}
                provider={Platform.OS != 'web'?PROVIDER_GOOGLE:'google'}
                // provider={PROVIDER_GOOGLE}
                showsUserLocation={true}
                showsMyLocationButton={true}
                googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}
                minZoomLevel={17}
                maxZoomLevel={19}
                // rotateEnabled={false}

                // REACTNATIVE WEB MAP
                onRegionChange={setRegion}

            >
                <Marker
                        coordinate={mapRegion}
                        title={'My Location'}
                        style={stylesMap.marker}
                    >
                        <MaterialIcons name="location-pin" color={primaryColor} size={36} />
                        
                    </Marker>
            </MapView>
        </View>
        <Text>{locationDisplayName}</Text>

        <Button 
            style={{
                marginTop: 20,
            }}
            buttonColor={primaryColor} mode='contained'
            onPress={ () => {
                navigation.navigate('ModalAbsenPhoto', {
                    photo: photo
                })
            }}
        >
                Lihat Foto
        </Button>

        <View style={{
            position: 'absolute',
            bottom: 15,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-evenly'
        }}>
            <Button buttonColor={primaryColor} mode='contained'
                onPress={handleCheckIn}
            >
                Check In
            </Button>
            <Button buttonColor={primaryColor} mode='contained'
                onPress={handleCheckIn}
            >
                Check Out
            </Button>
        </View>
    </View>
  )
}

const styles = StyleSheet.create({})


const stylesMap = StyleSheet.create({
    marker: {
      height: 48,
      width: 48
    },
    icon: {
        height: 22,
        width: 22,
        marginRight: 20,
        marginLeft: 10,
        alignSelf: 'center'
    },
    map: {
      width: '100%',
      height: '65%',
    },
  })