import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

import { Button } from 'react-native-paper';
import { primaryColor } from '../Constant';


export default function attendance({ route, navigation}) {
    
  return (
    <View style={{
      padding: 17, 
    }}>
        <Button
            style={{
                width: '50%',
                alignSelf: 'center',
                marginBottom: 10,
            }} 
            icon="camera" 
            mode="contained" 
            buttonColor={primaryColor} 
            onPress={() => {
              navigation.navigate('AbsenCamera')
            }}
        >
            Absen
        </Button>

        <Button
            style={{
                width: '50%',
                alignSelf: 'center',
                marginBottom: 10,
            }} 
            // icon="camera" 
            mode="contained" 
            buttonColor={primaryColor} 
            onPress={() => {
              navigation.navigate('/report')
            }}
        >
            Report
        </Button>
    </View>
  )
}

const styles = StyleSheet.create({})