import { StyleSheet, Text, View, ImageBackground } from 'react-native'
import React from 'react'
import axios from 'axios';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

export default function AppCameraPreview({photo}) {

    const navigation = useNavigation();
  React.useEffect(() => {
    console.log('params', photo && photo.uri)
    return () => {
        console.log('navi', navigation)
    }
  }, [])
  

  const getOpenMap = (lati,longi, type_check) => {
    console.log('check map')
  
    var config = {
      method: 'get',
      url: `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lati}&lon=${longi}`,
      headers: { }
    };
  
    axios(config)
    .then(function (response) {
        setLocationDisplayName(response.data.display_name)
        console.log("check mocked 2")
        console.log(mocked);
        console.log(response.data.display_name)
        console.log("search location")
        setCheckedMocked(mocked);
        if (mocked) {
          alert("gps f detected #2");
          return false;
        }
        
    })
    .catch(function (error) {
        alert("Check Security Location Data")
        setLoader(false)
        console.log(error);
        var mocked = text.mocked
    });
  }

  const handleNext = () => {
    navigation.navigate('Absen', {
        photo: photo
    })
  }

  return (
      <ImageBackground
        source={{uri: photo && photo.uri}}
        style={{
          flex: 1,
          // transform: [{scale: 1}],
          width: '100%',
          // height: '100%',
        }}
      >
        <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={handleNext}>
                <Text style={styles.text}>Absen</Text>
            </TouchableOpacity>
        </View>
      </ImageBackground>
  )
}

const styles = StyleSheet.create({
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        margin: 34,
        justifyContent: 'space-between',
      },
      button: {
        flex: 1,
        alignSelf: 'flex-end',
        alignItems: 'center',
      },
      text: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
      },
})