import { Camera, CameraType } from 'expo-camera';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-native-paper';
import {  StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import AppCameraPreview from './AppCameraPreview';
import { primaryColor } from '../Constant';

export default function AppCamera({ navigation }) {
    // BEFORE USE CAMERA  ON WEB PLATFORM DO DIS
    // rd /s /q node_modules\expo-camera\node_modules\react

    const [type, setType] = useState(CameraType.front);
    const [permission, requestPermission] = Camera.useCameraPermissions();
    const cameraRef = React.useRef();

    const [previewVisible, setPreviewVisible] = useState(false)
    const [capturedImage, setCapturedImage] = useState(null)

    useEffect( () => {
        console.log('cok navigation',navigation )
    },[])

  if (!permission) {
    // Camera permissions are still loading
    return (
        <View>
            <Text>Camera permission are still loading</Text>
        </View>
    );
  }

  if (!permission.granted) {
    // Camera permissions are not granted yet
    return (
      <View style={styles.container}>
        <Text style={{ textAlign: 'center' }}>We need your permission to show the camera</Text>
        {/* <Button onPress={requestPermission} title="grant permission" /> */}
        <Button
            style={{
                width: '50%',
                alignSelf: 'center',
                marginBottom: 10,
            }} 
            icon="camera" 
            mode="contained" 
            buttonColor={primaryColor} 
            onPress={() => {
                requestPermission()
            }}
        >
            Okay
        </Button>
      </View>
    );
  }

  function toggleCameraType() {
    setType(current => (current === CameraType.back ? CameraType.front : CameraType.back));
  }
  
  const __takePicture = async () => {
        if (!cameraRef.current) {
            console.log("oops")
            return false;
        } 
        const options = { quality: 0.2, base64: true, skipProcessing: false, scale: 1 };
        const photo = await cameraRef.current.takePictureAsync( options )
        console.log('photo senyum', photo && photo.uri)
        setPreviewVisible(true)
        setCapturedImage(photo)

        console.log('test', typeof photo)

        // router.push({
        //     pathname: '/absen-preview-photo',
        //     params: {
        //         photo: photo
        //     }
        // })

        // setTimeout(() => {
        //     router.push({
        //         pathname: '/absen-preview-photo',
        //         params: {
        //             photo: photo
        //         }
        //     })
        // }, 3000);
    }

  return (
    <View style={styles.container}>

        {
            previewVisible && capturedImage ?
                <AppCameraPreview photo={capturedImage} navigation={navigation} />
            :

            <Camera style={styles.camera} type={type}
                ref={cameraRef}

            >
                <View style={styles.buttonContainer}>
                {/* <TouchableOpacity style={styles.button} onPress={toggleCameraType}>
                    <Text style={styles.text}>Flip Camera</Text>
                </TouchableOpacity> */}
                <TouchableOpacity style={styles.button} onPress={__takePicture}>
                    <Text style={styles.text}>Shoot</Text>
                </TouchableOpacity>
                </View>
            </Camera>
        }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'transparent',
    margin: 34,
    justifyContent: 'space-between',
  },
  button: {
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
});
