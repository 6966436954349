import { StyleSheet, Text, TextInput, View } from 'react-native'
import React, { useState } from 'react'

import { Button } from 'react-native-paper';
import { ActivityIndicator, MD2Colors } from 'react-native-paper';
import { AuthSession_objectStoreData, AuthSession_stringStoreData } from '../helper/session/AuthSession';
import { primaryColor } from '../Constant';
import { ApiLogin } from '../api/LoginApi';
import { useAuth } from '../contexts/auth';



export default function Login() {
    const { signIn } = useAuth();
    const [loading, set_loading] = useState(false);
     
  const [_username, set_username] = useState('');
  const [_password, set_password] = useState('');

  const handleLogin = async () => {
    try {
        set_loading(true)
        const res = await ApiLogin({
            username: _username,
            password: _password,
        })
        set_loading(false)
        //  console.log('res res',res)
       if ( res.data.code == 200 ) {
            AuthSession_stringStoreData('id', res.data.data[0].id)
            AuthSession_stringStoreData('token', res.data.token)
            AuthSession_stringStoreData('@name', res.data.data[0].name,'')
            AuthSession_stringStoreData('@mobile_phone', res.data.data[0].phone,'')
            AuthSession_stringStoreData('@login', res.data.data[0].login,'')
            AuthSession_stringStoreData('@passwordx', _password,'')
            AuthSession_stringStoreData('@employee_id', res.data.data[0].employee_id[0],'')
            AuthSession_stringStoreData('@employee_name', res.data.data[0].employee_id[1],'')
            AuthSession_stringStoreData('@company_id', res.data.data[0].company_id[0],'')
            AuthSession_stringStoreData('@company_name', res.data.data[0].company_id[1],'')
            AuthSession_stringStoreData('@profile_image', res.data.data[0].image_1920,'')
            AuthSession_objectStoreData('partnerData', res.data.partnerData)
            signIn();
       } else {
            alert(res.data.message)
       }
        
    } catch (error) {
        if (error.response.data.code == '500') {
            
        } 
        console.log('error api login', error);
        alert(error)
        // alert(error.response.data.message)
    }
  }

  if ( loading ) {
    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator animating={true} color={primaryColor} />
        </View>

    )
  }

  return (
    <View style={{ flex: 1, padding: 17, justifyContent: 'center' }}>
        <View style={{marginBottom: 10,}}>
            <Text style={{ marginBottom: 5}}>Email</Text>
            <View>
            <TextInput
                keyboardType='default'
                placeholder='Username'
                style={{
                borderWidth: 1, paddingVertical: 8, paddingHorizontal: 12, borderRadius: 6
                }}

                value={_username}
                onChangeText={( text ) => {
                set_username(text)
                }}
            />
            </View>
        </View>

        <View style={{ marginBottom: 10}}>
            <Text style={{ marginBottom: 5}}>Password</Text>
            <View>
            <TextInput
                secureTextEntry={true}
                keyboardType='default'
                placeholder='Password'
                style={{
                borderWidth: 1, paddingVertical: 8, paddingHorizontal: 12, borderRadius: 6
                }}

                value={_password}
                onChangeText={( text ) => {
                set_password(text)
                }}
            />
            </View>
        </View>
        
        <View style={{width: undefined}}>
            <Button
                style={{
                    width: '50%',
                    alignSelf: 'center',
                    marginBottom: 10,
                }} 
                icon="camera" 
                mode="contained" 
                buttonColor={primaryColor} 
                onPress={() => {
                    handleLogin()
                }}
            >
                Login
            </Button>
        </View>
    </View>
  )
}

const styles = StyleSheet.create({})